@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Customize the scrollbar width */
  /* Note: The "w-[8px]" class is used to set the scrollbar width to 8px */
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Customize the scrollbar height */
  /* Note: The "h-[8px]" class is used to set the scrollbar height to 8px */
  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background:#FFFFF6;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(170, 34, 51);
    border-radius: 4px;
  }
